import React, { useEffect, useState } from "react"
import {
  Card,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  Checkbox, 
  Grid, 
  Box, 
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button, 
  FormControl, 
  Select, 
  InputLabel,
  MenuItem, 
  IconButton,
  Paper
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid";
import Logo from "./assets/images/logo.png"
import DeleteIcon from "@mui/icons-material/Delete";
import "./App.css"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import CircularProgress from "@mui/material/CircularProgress"
import { calculateTotalSum, submitFormApi, validateForm } from "./util"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import BenefitsSelectedTable from "./Components/BenefitsSelectedTable"

const totalAmountAllowed = 12000

function App() {
  const [isLoading, setIsLoading] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [totalSelectedBenefitAmount, setTotalSelectedbenefitAmount] =
    useState(0)
  const [errors, setErrors] = useState({})
  const [benefitsCheckbox, setBenefitsCheckbox] = useState({})
  const [tempChild, setTempChild] = useState({
    id: null,
    child_name: "",
    school_college_name: "",
    standard_grade: "",
  });
  const [children, setChildren] = useState([]);
  const [formFields, setFormFields] = useState({
    fullName: "",
    gender: "",
    dateOfBirth: "",
    isPreviousSaathiMember: "",
    previousSaathiCardNo: "",
    educationQualification: "",
    contactNumber: "",
    residentialAddress: "",
    pincode: "",
    aadhaarCardNumber: "",
    panCardNumber: "",
    monthlySalaryRange: "",
    isRetiredPerson: "",
    isDisabled: "",
    disabilityDescription: "",
    maritalStatus: "",
    typeOfHouse: "",
    haveChildren: "",
    requireScholarship: "",
    registeredMemberOfFWICE: "",
    sectorOfFilmIndustry: "",
    bankName:"",
    bankAccountNumber: "",
    ifscCode: "",
    bankBranchName: "",
    haveRationCard: "",
    rationCardColor: "",
    ayushmanBharatCard: "",
    benefitFrequency: {
      education: "half_yearly",
      ration: "quaterly",
      travel: "yearly"
    },
    benefits: {
      education: 0,
      ration: 0,
      travel: 0
    },
  })

  useEffect(() => {

    const totalSelectedAmount = calculateTotalSum(formFields.benefits)
    setTotalSelectedbenefitAmount(totalSelectedAmount)
    if (totalSelectedAmount > totalAmountAllowed) toast.error(`Total selected amount should not be more than ${totalAmountAllowed}`)

  }, [formFields.benefits])

  const handleFormChange = (e) => {

    let targetValue = e.target.value;

    if(targetValue === ""){
      targetValue = 0;
    }



    if (
      e.target.name === "rationSelect" ||
      e.target.name === "educationSelect" ||
      e.target.name === "travelSelect"
    ) {
      setFormFields((prevValue) => {
        return {
          ...prevValue,
          benefitFrequency: {
            ...prevValue.benefitFrequency,
            [e.target.name === "rationSelect" ? "ration" : e.target.name === "educationSelect" ? "education" : "travel"]: targetValue,
          },
        }
      })

      return
    }

    if (
      e.target.name === "ration" ||
      e.target.name === "education" ||
      e.target.name === "travel"
    ) {

      if(e.target.name === "travel" && targetValue > 2000) return toast.error("Maximum allowed for travel is 2000")
      if(e.target.name === "ration" && targetValue > 10000) return toast.error("Maximum allowed for ration is 10000")
      if(e.target.name === "education" && targetValue > 10000) return toast.error("Maximum allowed for education is 10000")

      if(e.target.name === "ration") {
        // const educationBalance = parseInt(formFields.benefits.education);
        // const travelBalance = parseInt(formFields.benefits.travel);
        const userSelectedAmount = parseInt(targetValue);
        console.log("VCalue ", targetValue );
        console.log(userSelectedAmount);
        const MAX_TOTOL_AMOUNT = 12000;

        setFormFields((prevValue) => {
          return {
            ...prevValue,
            benefits: {
              ...prevValue.benefits,
              ration: userSelectedAmount,
              education: (MAX_TOTOL_AMOUNT - parseInt(formFields.benefits.travel)) - userSelectedAmount,
            }
          }
        })

        handleRemoveZero();
      }

      if(e.target.name === "education") {
        // const educationBalance = parseInt(formFields.benefits.education);
        const userSelectedAmount = parseInt(targetValue);
        const MAX_TOTOL_AMOUNT = 12000;

        setFormFields((prevValue) => {
          return {
            ...prevValue,
            benefits: {
              ...prevValue.benefits,
              ration: (MAX_TOTOL_AMOUNT - parseInt(formFields.benefits.travel)) - userSelectedAmount,
              education: userSelectedAmount
            }
          }
        })

        handleRemoveZero();
      }

      if(e.target.name === "travel") {
        const userSelectedAmount = parseInt(targetValue);
        const MAX_TOTOL_AMOUNT = 12000;

        setFormFields((prevValue) => {
          return {
            ...prevValue,
            benefits: {
              ration: (MAX_TOTOL_AMOUNT - userSelectedAmount) - userSelectedAmount,
              education: userSelectedAmount,
              travel: userSelectedAmount  
            }
          }
        })

        handleRemoveZero();
      }
      

      // setFormFields((prevValue) => {
      //   return {
      //     ...prevValue,
      //     benefits: {
      //       ...prevValue.benefits,
      //       [e.target.name]: targetValue,
      //     },
      //   }
      // })

      return
    }



    setFormFields((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }))
  }

  const handleRemoveZero = () => {
    console.log(formFields.benefits);
  }

  const handleBenefitsCheckBox = (e) => {
    setBenefitsCheckbox((prevValue) => {
      return {
        ...prevValue,
        [e.target.name]: e.target.checked,
      }
    })
  }

  const handleFormSubmit = async () => {
    try {
      // Check for the total benefits selected

      // TODO: Check for child list
      if(formFields.contactNumber.length !== 10) return toast.error("Phone Number should be 10 digits long") 
      console.log(formFields.benefitFrequency);
      if(formFields.benefitFrequency.ration === "") return toast.error("Please select the frequency of ration")
      if(formFields.benefitFrequency.education === "") return toast.error("Please select the frequency of education")


      const totalBenefitAmount = calculateTotalSum(formFields.benefits)
      if (totalBenefitAmount !== totalAmountAllowed)
        return toast.error(
          `Total selected amount should not be more than ${totalAmountAllowed}`,
        )

      if(parseInt(formFields.benefits.travel) > 2000) return toast.error("Travel Amount cannot be more than 2000");
      if(parseInt(formFields.benefits.ration) > 10000) return toast.error("Ration Amount cannot be more than 10000");
      if(parseInt(formFields.benefits.education) > 10000) return toast.error("Education Amount cannot be more than 10000");

      const errorResult = validateForm(formFields)

      // if(formData.haveRationCard === "yes" && formData.rationCardColor.length === 0) {
      //   toast.error("Please enter ration card color");
      // }

      console.log(errorResult);
      console.log(errorResult)


      if(formFields.haveChildren === "yes" && children.length === 0) {
        errorResult.children = {
          error: true,
          message: 'Please add at least one child',
        }
      }
      setErrors(errorResult)
      console.log(errorResult);
      if (Object.keys(errorResult).length !== 0)
        return toast.error("Please fill all required fields")

      const formData = formFields

      if (formData["isPreviousSaathiMember"] === "no")
        formData["previousSaathiCardNo"] = ""
      if (formData["isDisabled"] === "no")
        formData["disabilityDescription"] = ""
      if (formData["haveChildren"] === "no") formData["numberOfChildren"] = ""

      formData.children = children;

      const result = await submitFormApi(formData)
      if(result?.data?.statusCode === 422 || result?.data?.statusCode === 500) {
        return toast.error(result.data.message || 
          "Unable to submit form")
      }

      // Make all the form empty again
      const tempFormFields = formFields
      Object.keys(formFields).forEach(
        (formField) => (tempFormFields[formField] = ""),
      )
      setFormFields(tempFormFields)
      setFormSubmitted(true)
      toast.success("Successfully submitted!")
    } catch (error) {
      console.log(error)
      toast.error("There was some error, please try again after sometime")
    }
  }

  const handleAddChild = () => {
    console.log("hey")
    setChildren((prevValue) => [...prevValue, tempChild])
    setTempChild({
      id: null,
      child_name: "",
      school_college_name: "",
      standard_grade: "",
    })
    console.log(children);
  }

  const handleChildChange = (e) => {
    let currrentInputElementValue = e.target.value;

    // Get children length to update the id
    let childrenCount = children.length;

    setTempChild((prevValue) => {
      return {
        ...prevValue,
        id: childrenCount + 1,
        [e.target.id]: currrentInputElementValue,
      };
    });

    console.log(tempChild);
  }

  const columns = [
    { field: "child_name", headerName: "Name", flex: 1 },
    { field: "school_college_name", headerName: "School/College", flex: 1 },
    { field: "standard_grade", headerName: "Standard/Grade", flex: 1 },
    {
      field: "edit",
      headerName: "Edit",
      renderCell: (param) => (
        <IconButton
          sx={{ width: "40px" }}
          onClick={async () => {
            const selectedIDs = new Set([param.row.id]);
         
            let filteredChildren = children.filter(
              (x) => !selectedIDs.has(x.id)
            );
            setChildren(filteredChildren);
          }}
        >
          <DeleteIcon
            sx={{
              width: "20px",
              color: "#ba000d",
            }}
          />
        </IconButton>
      ),
    },
  ];

  return (
    <div className="applicationformmicrosite-container">
      {/* <Card className="banner-card">
        <div
          style={{
            marginLeft: "30px",
          }}
        >
          <Typography variant="h5" className="applicationform-title">
            Application form closed
          </Typography>
        </div>
      </Card> */}
      <ToastContainer />
      <Card className="banner-card">
        <img src={Logo} alt="logo" className="applicationform-logo" />
        <div
          style={{
            marginLeft: "30px",
          }}
        >
          <Typography variant="h5" className="applicationform-title">
            Saathi Application form 2025- 2026
          </Typography>
          <Typography variant="h5" className="applicationform-title">
            साथी आवेदन पत्र 2025-2026
          </Typography>
        </div>
      </Card>
      <Card className="field-card">
      <Typography variant="h5"  className="applicationform-title" sx={{
          fontSize: "18px",
          margin: "25px 25px"
        }}>
        <span style={{fontWeight: "bold"}}>YCF Saathi Application form 2025-2026 is now open!</span><br/><br />

        Fill out the form and wait for a message from YCF.<br /><br/>

        Only those selected for the program (according to eligibility criteria) will be notified.<br /><br />

        वाईसीएफ साथी आवेदन पत्र 2025-2026 अब खुला है!
        फ़ॉर्म भरें और YCF के संदेश की प्रतीक्षा करें।
        केवल कार्यक्रम के लिए चुने गए लोगों (पात्रता मानदंडों के अनुसार) को सूचित किया जाएगा।
          </Typography>
      </Card>
      {formSubmitted && <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">Thank you, your form is submitted now.
YCF will message you if you get selected for the Saathi program
Please be patient</Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
          धन्यवाद, आपका फॉर्म अब सबमिट हो गया है।
यदि आप साथी कार्यक्रम के लिए चयनित हो जाते हैं तो वाईसीएफ आपको संदेश भेजेगा
कृपया धैर्य रखें
          </Typography>
          {/* <Typography variant="body1" textAlign={"right"} style={{ marginBottom: "15px" }}>
            <Button onClick={() => {
              setFormSubmitted(false);
              window.location.reload(true)
            }}>Submit another application</Button>
          </Typography> */}
        </div>
      </Card>}
      
      {!formSubmitted && <>
        <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">Full Name</Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            पूरा नाम
          </Typography>
          <TextField
            id="fullName"
            name="fullName"
            variant="standard"
            fullWidth
            placeholder="your answer"
            onChange={handleFormChange}
          />
          <p className="error-text">{errors?.fullName?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">Choose your Gender</Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            अपना लिंग चुनें
          </Typography>
          <RadioGroup column name="gender" onChange={handleFormChange}>
            <FormControlLabel
              value="female"
              control={<Radio />}
              label="Female"
            />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
          <p className="error-text">{errors?.gender?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">
            Are you a registered member of the Federation of Western India Cine
            Employees (FWICE)
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            क्या आप फेडरेशन ऑफ वेस्टर्न इंडिया सिने एम्प्लॉइज (FWICE) के पंजीकृत
            सदस्य हैं?
          </Typography>
          <RadioGroup
            column
            name="registeredMemberOfFWICE"
            onChange={handleFormChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
          <p className="error-text">
            {errors?.registeredMemberOfFWICE?.message}
          </p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">Enter your date of birth</Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            आपका जन्म तारीख प्रवेश करे
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                label="Date of birth"
                value={formFields.dateOfBirth}
                onChange={(newValue) =>
                  setFormFields((prevValue) => ({
                    ...prevValue,
                    dateOfBirth: newValue,
                  }))
                }
              />
            </DemoContainer>
          </LocalizationProvider>
          <p className="error-text">{errors?.dateOfBirth?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">Previous saathi member?</Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            पिछला साथी सदस्य?
          </Typography>
          <RadioGroup
            column
            name="isPreviousSaathiMember"
            onChange={handleFormChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes/हाँ" />
            <FormControlLabel value="no" control={<Radio />} label="No/नहीं" />
          </RadioGroup>
          <p className="error-text">
            {errors?.isPreviousSaathiMember?.message}
          </p>
        </div>
      </Card>

      {formFields.isPreviousSaathiMember === "yes" && (
        <Card className="field-card">
          <div
            style={{
              padding: "30px 24px",
            }}
          >
            <Typography variant="body1">
              If yes, Write your previous saathi card no?
            </Typography>
            <Typography variant="body1" style={{ marginBottom: "15px" }}>
              यदि हां, तो अपना पिछला साथी कार्ड नंबर लिखें?
            </Typography>
            <TextField
              id="previousSaathiCardNo"
              name="previousSaathiCardNo"
              variant="standard"
              fullWidth
              placeholder="your answer"
              onChange={handleFormChange}
            />
            <p className="error-text">
              {errors?.previousSaathiCardNo?.message}
            </p>
          </div>
        </Card>
      )}
      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">
            Your Highest education qualification?
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            आपकी उच्चतम शिक्षा योग्यता?
          </Typography>
          <RadioGroup
            column
            name="educationQualification"
            onChange={handleFormChange}
          >
            <FormControlLabel
              value="till class 10th"
              control={<Radio />}
              label="Till Class 10th दसवीं कक्षा तक"
            />
            <FormControlLabel
              value="till class 12th"
              control={<Radio />}
              label="Till Class 12th 12वीं कक्षा तक"
            />
            <FormControlLabel
              value="graduation"
              control={<Radio />}
              label="Graduation स्नातक"
            />
            <FormControlLabel
              value="post graduation"
              control={<Radio />}
              label="Post graduation पोस्ट ग्रेजुएशन"
            />
            <FormControlLabel
              value="diploma"
              control={<Radio />}
              label="Diploma डिप्लोमा"
            />
            <FormControlLabel
              value="no education"
              control={<Radio />}
              label="No education कोई पढ़ाई नही"
            />
          </RadioGroup>
          <p className="error-text">
            {errors?.educationQualification?.message}
          </p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">
            Enter your contact number (Please provide number available on
            WhatsApp)
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            अपना संपर्क नंबर दर्ज करें (कृपया WhatsApp पर उपलब्ध नंबर प्रदान
            करें)
          </Typography>
          <TextField
            id="contactNumber"
            name="contactNumber"
            variant="standard"
            fullWidth
            placeholder="eg. 7712432333"
            onChange={handleFormChange}
          />
          <p className="error-text">{errors?.contactNumber?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">
            Enter your full residential address
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            अपना पूरा आवासीय पता दर्ज करें
          </Typography>
          <TextField
            id="residentialAddress"
            name="residentialAddress"
            variant="standard"
            fullWidth
            placeholder="your answer"
            onChange={handleFormChange}
          />
          <p className="error-text">{errors?.residentialAddress?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">Pincode</Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            पिन कोड
          </Typography>
          <TextField
            id="pincode"
            name="pincode"
            variant="standard"
            fullWidth
            placeholder="your answer"
            onChange={handleFormChange}
          />
          <p className="error-text">{errors?.pincode?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">Aadhar card number</Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            आधार कार्ड नंबर
          </Typography>
          <TextField
            id="aadhaarCardNumber"
            name="aadhaarCardNumber"
            variant="standard"
            fullWidth
            placeholder="your answer"
            onChange={handleFormChange}
          />
          <p className="error-text">{errors?.aadhaarCardNumber?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">PAN Card Number</Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            पैन कार्ड नंबर
          </Typography>
          <TextField
            id="panCardNumber"
            name="panCardNumber"
            variant="standard"
            fullWidth
            placeholder="your answer"
            onChange={handleFormChange}
          />
          <p className="error-text">{errors?.panCardNumber?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">Do you have ration card?</Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            क्या आपके पास राशन कार्ड है?
          </Typography>
          <RadioGroup
            column
            name="haveRationCard"
            onChange={handleFormChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes/हाँ" />
            <FormControlLabel value="no" control={<Radio />} label="No/नहीं" />
          </RadioGroup>
          <p className="error-text">
            {errors?.haveRationCard?.message}
          </p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">What colour is your ration card?
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
          आपका राशन कार्ड किस रंग का है?
          </Typography>
          <TextField
            id="rationCardColor"
            name="rationCardColor"
            variant="standard"
            fullWidth
            placeholder="your answer"
            onChange={handleFormChange}
          />
          <p className="error-text">{errors?.rationCardColor?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">Do you have Ayushmaan Bharat Health insurance card?</Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
          क्या आपके पास आयुष्मान भारत स्वास्थ्य बीमा कार्ड है?
          </Typography>
          <RadioGroup
            column
            name="ayushmanBharatCard"
            onChange={handleFormChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes/हाँ" />
            <FormControlLabel value="no" control={<Radio />} label="No/नहीं" />
          </RadioGroup>
          <p className="error-text">
            {errors?.ayushmanBharatCard?.message}
          </p>
        </div>
      </Card>


      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">Bank Name</Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            बैंक का नाम
          </Typography>
          <TextField
            id="bankName"
            name="bankName"
            variant="standard"
            fullWidth
            placeholder="your answer"
            onChange={handleFormChange}
          />
          <p className="error-text">{errors?.bankName?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">Bank Account Number</Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            बैंक खाता संख्या
          </Typography>
          <TextField
            id="bankAccountNumber"
            name="bankAccountNumber"
            variant="standard"
            fullWidth
            placeholder="your answer"
            onChange={handleFormChange}
          />
          <p className="error-text">{errors?.bankAccountNumber?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">IFSC Code</Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            आईएफएससी कोड
          </Typography>
          <TextField
            id="ifscCode"
            name="ifscCode"
            variant="standard"
            fullWidth
            placeholder="your answer"
            onChange={handleFormChange}
          />
          <p className="error-text">{errors?.ifscCode?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">Bank Branch Name</Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            बैंक शाखा का नाम
          </Typography>
          <TextField
            id="bankBranchName"
            name="bankBranchName"
            variant="standard"
            fullWidth
            placeholder="your answer"
            onChange={handleFormChange}
          />
          <p className="error-text">{errors?.bankBranchName?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">
            Choose your monthly salary range
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            अपनी मासिक वेतन सीमा चुनें
          </Typography>
          <RadioGroup
            column
            name="monthlySalaryRange"
            onChange={handleFormChange}
          >
            <FormControlLabel
              value="Less than or equal to ₹10,000"
              control={<Radio />}
              label="Less than or equal to ₹10,000/₹10,000 से कम या इसके बराबर"
            />
            <FormControlLabel
              value="₹11,000 to ₹20,000"
              control={<Radio />}
              label="₹11,000 to ₹20,000/₹11,000 से ₹20,000"
            />
            <FormControlLabel
              value="₹21,000 to ₹30,000"
              control={<Radio />}
              label="₹21,000 to ₹30,000/₹21,000 से ₹30,000"
            />
            <FormControlLabel
              value="₹31,000 to ₹40,000"
              control={<Radio />}
              label="₹31,000 to ₹40,000/₹31,000 से ₹40,000"
            />
            <FormControlLabel
              value="₹41,000 to ₹50,000"
              control={<Radio />}
              label="₹41,000 to ₹50,000/₹41,000 से ₹50,000"
            />
            <FormControlLabel
              value="More than ₹50,000"
              control={<Radio />}
              label="More than ₹50,000/₹50,000 से अधिक"
            />
          </RadioGroup>
          <p className="error-text">{errors?.monthlySalaryRange?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">
            Do you fall under the 'Retired Person' Category?/Are you above 60 years of age?
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            क्या आप 'सेवानिवृत्त व्यक्ति' श्रेणी में आते हैं?
          </Typography>
          <RadioGroup column name="isRetiredPerson" onChange={handleFormChange}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes/हाँ" />
            <FormControlLabel value="no" control={<Radio />} label="No/नहीं" />
          </RadioGroup>
          <p className="error-text">{errors?.isRetiredPerson?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">
            Do you fall under the 'Disabled' Category?
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            क्या आप 'विकलांग' श्रेणी में आते हैं?
          </Typography>
          <RadioGroup column name="isDisabled" onChange={handleFormChange}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes/हाँ" />
            <FormControlLabel value="no" control={<Radio />} label="no/नहीं" />
          </RadioGroup>
          <p className="error-text">{errors?.isDisabled?.message}</p>
        </div>
      </Card>
      {formFields.isDisabled === "yes" && (
        <Card className="field-card">
          <div
            style={{
              padding: "30px 24px",
            }}
          >
            <Typography variant="body1">
              If yes, mention your disability
            </Typography>
            <Typography variant="body1" style={{ marginBottom: "15px" }}>
              यदि हाँ, तो अपनी विकलांगता का उल्लेख करें
            </Typography>
            <TextField
              id="disabilityDescription"
              name="disabilityDescription"
              variant="standard"
              fullWidth
              placeholder="your answer"
              onChange={handleFormChange}
            />
            <p className="error-text">
              {errors?.disabilityDescription?.message}
            </p>
          </div>
        </Card>
      )}

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">Your Marital status</Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            अपनी वैवाहिक स्थिति
          </Typography>
          <RadioGroup column name="maritalStatus" onChange={handleFormChange}>
            <FormControlLabel value="male" control={<Radio />} label="Single" />
            <FormControlLabel
              value="married"
              control={<Radio />}
              label="Married"
            />
            <FormControlLabel
              value="divorced"
              control={<Radio />}
              label="Divorced"
            />
            <FormControlLabel
              value="separated"
              control={<Radio />}
              label="Separated"
            />
            <FormControlLabel
              value="widowed"
              control={<Radio />}
              label="Widowed"
            />
          </RadioGroup>
          <p className="error-text">{errors?.maritalStatus?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">Type of house you live in?</Typography>
          <Typography
            variant="body1"
            style={{ marginBottom: "15px" }}
          >आप किस प्रकार के घर में रहते हैं?</Typography>
          <RadioGroup column name="typeOfHouse" onChange={handleFormChange}>
            <FormControlLabel
              value="own house"
              control={<Radio />}
              label="Own House"
            />
            <FormControlLabel
              value="rented"
              control={<Radio />}
              label="Rented"
            />
          </RadioGroup>
          <p className="error-text">{errors?.typeOfHouse?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">Do you have children?</Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            आपके बच्चे है क्या?
          </Typography>
          <RadioGroup column name="haveChildren" onChange={handleFormChange}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
          <p className="error-text">{errors?.haveChildren?.message}</p>
        </div>
      </Card>
      {formFields.haveChildren === "yes" && (
        <Card className="field-card">
          <div style={{
            padding: "30px 24px",
          }}>
            <Typography variant="body1">Please add you child details</Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            कृपया अपने बच्चे का विवरण जोड़ें
          </Typography>

          <Typography variant="body1" style={{marginBottom: "20px"}}>Note: After filling the details, click on the (ADD CHILD) button/विवरण भरने के बाद (ADD CHILD) बटन पर क्लिक करें</Typography>
            <Grid container spacing={1}>
           
              <Grid item xs={6}>
                  <TextField
                    id="child_name"
                    label="Child Name"
                    name="child_name"
                    variant="outlined"
                    size="small"
                    onChange={handleChildChange}
                    value={tempChild.child_name}
                    fullWidth
                    // onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="school_college_name"
                    name="school_college_name"
                    label="School/College Name"
                    variant="outlined"
                    size="small"
                    onChange={handleChildChange}
                    value={tempChild.school_college_name}
                    fullWidth
                    // onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={6}>
                
                  <TextField
                    id="standard_grade"
                    name="standard_grade"
                    label="Standard/Grade"
                    variant="outlined"
                    size="small"
                    onChange={handleChildChange}
                    value={tempChild.standard_grade}
                    fullWidth
                    // onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={6}>
                <Box display="flex" height="100%" alignItems="center">
                  <Button color="error" variant="outlined" onClick={handleAddChild}>
                    Add Child
                  </Button>
                </Box>
              </Grid>
              {!!children.length && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      height: "200px",
                      backgroundColor: "#fff",
                      borderRadius: "5px",
                    }}
                  >
                    <DataGrid
                      rows={children}
                      columns={columns}
                      disableSelectionOnClick
                      getRowHeight={() => "auto"}
                    />
                  </Box>
                </Grid>
              )}
              </Grid>
              <p className="error-text">
                {errors?.children?.message}
              </p>
          </div>
          
         
        </Card>
    
      )}

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">
            Do you require scholarship for higher studies for your children?
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            क्या आपको अपने बच्चों की उच्च शिक्षा के लिए छात्रवृत्ति की आवश्यकता
            है?
          </Typography>
          <RadioGroup
            column
            name="requireScholarship"
            onChange={handleFormChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
          <p className="error-text">{errors?.requireScholarship?.message}</p>
        </div>
      </Card>

      

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">
            Which sectors of the film industry do you primarily work in?
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            आप मुख्य रूप से फिल्म उद्योग के किन क्षेत्रों में काम करते हैं?
          </Typography>
          <RadioGroup
            column
            name="sectorOfFilmIndustry"
            onChange={handleFormChange}
          >
            <FormControlLabel
              value="production"
              control={<Radio />}
              label="Production"
            />
            <FormControlLabel
              value="direction"
              control={<Radio />}
              label="Direction"
            />
            <FormControlLabel
              value="cinematography"
              control={<Radio />}
              label="Cinematography"
            />

            <FormControlLabel
              value="acting"
              control={<Radio />}
              label="Acting"
            />

            <FormControlLabel
              value="art & set design"
              control={<Radio />}
              label="Art & Set Design"
            />

            <FormControlLabel
              value="costume & makeup"
              control={<Radio />}
              label="Costume & Makeup"
            />

            <FormControlLabel
              value="sound & music"
              control={<Radio />}
              label="Sound & Music"
            />

            <FormControlLabel
              value="setting department"
              control={<Radio />}
              label="Setting department"
            />

            <FormControlLabel
              value="others"
              control={<Radio />}
              label="Others"
            />
          </RadioGroup>
          <p className="error-text">{errors?.sectorOfFilmIndustry?.message}</p>
        </div>
      </Card>

      <Card className="field-card">
        <div
          style={{
            padding: "30px 24px",
          }}
        >
          <Typography variant="body1">
            Choose the most required services from YCF (Select any 3)
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            YCF से सबसे आवश्यक सेवाएँ चुनें (कोई भी 3 चुनें)
          </Typography>
          <div
            style={{
            
            }}
          >
            <div>
            <Typography variant="body1" style={{ margin: "40px 0 5px 0" }}>
            Instruction/अनुदेश
            </Typography>
            <ol style={{marginLeft: "15px"}}>
              <li> <Typography variant="body1" style={{ margin: "5px 0" }}>
                  Total amount should add up to 12000/कुल रकम 12000 तक जुड़नी चाहिए
            </Typography></li>
            <li> <Typography variant="body1" style={{ margin: "5px 0" }}>
                  Total amount for travel cannot exceed 2000/यात्रा के लिए कुल राशि 2000 से अधिक नहीं हो सकती
            </Typography></li>
            
            <li> <Typography variant="body1" style={{ margin: "5px 0" }}>
              Max limit for ration & education is Rs.10000/राशन और शिक्षा के लिए अधिकतम सीमा 10000 रुपये है
            </Typography></li>

            <li> <Typography variant="body1" style={{ margin: "5px 0" }}>
              Please select the frequency option for the benefits/कृपया लाभों के लिए आवृत्ति विकल्प चुनें
            </Typography></li>
            </ol>
            </div>
           
          </div>
          <div style={{marginTop: "40px"}}>
                <TableContainer component={Paper}>
                    <Table sx={{ width: "100%" }}>
                      <TableHead>
                        <TableRow sx={{background: "#dedede"}}>
                          <TableCell align="start" sx={{border: "2px solid rgb(219, 218, 218)"}}>Benefit</TableCell>
                          <TableCell align="start" sx={{border: "2px solid rgb(219, 218, 218)"}}>Amount</TableCell>
                          <TableCell align="start" sx={{border: "2px solid rgb(219, 218, 218)"}}>Freqeuncy</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                                <TableRow>
                                  <TableCell align="left" sx={{width: "30%"}}>Ration (राशन)</TableCell>
                                  <TableCell align="left">
                                  <TextField
                                    id="ration"
                                    name="ration"
                                    variant="standard"
                                    placeholder="Enter a amount/राशि डालें"
                                    type="number"
                                    value={formFields.benefits.ration.toString()}
                                    onChange={handleFormChange}
                                    sx={{ marginBottom: 3 }}
                                    fullWidth
                                  />
                                  </TableCell>
                                  <TableCell align="left">
                                  <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">Frequency</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      name="rationSelect"
                                      onChange={handleFormChange}
                                      value={"quaterly"}
                                      disabled
                                    >
                                      <MenuItem value={"quaterly"}>Quaterly</MenuItem>
                                      <MenuItem value={"half_yearly"}>Half Yearly</MenuItem>
                                    </Select>
                                  </FormControl>
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell align="left">Education of Children (बच्चों की शिक्षा)</TableCell>
                                  <TableCell align="left">
                                  <TextField
                                      id="education"
                                      name="education"
                                      variant="standard"
                                      placeholder="Enter a amount/राशि डालें"
                                      onChange={handleFormChange}
                                      type="number"
                                      value={formFields.benefits.education.toString()}
                                      sx={{ marginBottom: 3 }}
                                      fullWidth
                                    />
                                  </TableCell>
                                  <TableCell align="left">
                                  <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">Frequency</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      // value={formFields.benefitFrequency.education}
                                      name="educationSelect"
                                      onChange={handleFormChange}
                                      value={"half_yearly"}
                                      disabled
                                    >
                                      <MenuItem value={"half_yearly"}>Half Yearly</MenuItem>
                                      <MenuItem value={"yearly"}>Annually</MenuItem>
                                    </Select>
                                  </FormControl>
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell align="left">Annual one time travel reimbursement to hometown (गृहनगर के लिए वार्षिक एकमुश्त यात्रा प्रतिपूर्ति)</TableCell>
                                  <TableCell align="left">
                                  <TextField
                                    id="travel"
                                    name="travel"
                                    variant="standard"
                                    type="number"
                                    value={formFields.benefits.travel.toString()}
                                    placeholder="Enter a amount/राशि डालें"
                                    onChange={handleFormChange}
                                    fullWidth
                                  />
                                  </TableCell>
                                  <TableCell align="left">
                                  <FormControl fullWidth size="small" disabled>
              <InputLabel id="demo-simple-select-label">Frequency</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={formFields.benefitFrequency.travel}
                name="travelSelect"
                onChange={handleFormChange}
                fullWidth
                defaultValue={"yearly"}

              >
                <MenuItem value={"yearly"} selected={true}>Annually</MenuItem>
              </Select>
            </FormControl>
                                  </TableCell>
                                  </TableRow>

                                  <TableRow sx={{background: "#dedede"}}>
                                    <TableCell align="left" sx={{fontWeight: "bold"}}>Total Amount</TableCell>
                                    <TableCell align="left" colSpan={2}>
                                        {totalSelectedBenefitAmount}
                                    </TableCell>
                                  </TableRow>
                      </TableBody>
                    </Table>
                </TableContainer>
          </div>
          
      
        </div>
      </Card>
      <div className="field-card">
        <Button
          variant="contained"
          size="large"
          color="error"
          sx={{
            marginTop: "14px",
            width: "160px",
            height: "45px",
          }}
          disabled={isLoading}
          onClick={handleFormSubmit}
        >
          {isLoading ? (
            <CircularProgress sx={{ color: "#fff" }} size={20} />
          ) : (
            "Submit"
          )}
        </Button>
      </div>
      </>}
     

      <Typography
        style={{
          marginTop: "80px",
          opacity: "0.5",
        }}
      >
        &copy; 2025 Yash Chopra Foundation
      </Typography> 
    </div>
  )
}

export default App
