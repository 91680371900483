// const SERVER_LINK = "http://localhost:8080";
const SERVER_LINK = "https://saathiserver.yashchoprafoundation.org";

export const submitFormApi = async (data) => {
  await fetch(`${SERVER_LINK}/api/applicationform`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  }).then((response) => {

    return response;
  }).catch((err) => {
    throw Error(err);
  })
}

export const validateForm = (formData) => {
  let errorsObject = {}
  Object.keys(formData).forEach((formField) => {

    if(formField === "haveRationCard") return;
    if(formField === "rationCardColor") return;

    if(formField === "contactNumber" &&  formData[formField].length !== 10) {
      errorsObject[formField] = {
        error: true,
        message: "Phone number should be 10 digits long",
      }
      return;
    }
   
    if (formData[formField].length === 0) {
      // If the user has selected no in the previous question of anyof
      // the below questions then allow the current question to be empty
      if (formField === "previousSaathiCardNo" && formData["isPreviousSaathiMember"] === "no") return
      if (formField === "disabilityDescription" &&formData["isDisabled"] === "no") return
      if (formField === "numberOfChildren" && formData["haveChildren"] === "no") return

      errorsObject[formField] = {
        error: true,
        message: "Cannot be nulled. Please enter something",
      }

    }

    const errorPreviousSaathiCardNo = allowEmpty(formData, formField, "previousSaathiCardNo", "isPreviousSaathiMember");
    const errorDisabilityDescription = allowEmpty(formData, formField, "disabilityDescription", "isDisabled");
    const errorNumberOfChildren = allowEmpty(formData, formField, "numberOfChildren", "haveChildren");

    if(errorPreviousSaathiCardNo) errorsObject[formField] = errorPreviousSaathiCardNo
    if(errorDisabilityDescription) errorsObject[formField] = errorDisabilityDescription
    if(errorNumberOfChildren) errorsObject[formField] = errorNumberOfChildren
  })

  return errorsObject
}

// --- Concept ---
// Some questions are places such that if a user chooses "yes" then they have to provided a description for that
// for eg. if the question asks "Are you disabled?" and users chooses yes then they will have to enter their disability in the next question
// @params
// formData = object with formfields and its data
// currentFormField = current form field, usually when looping through formData
// formFieldToCheck = pass the form field name which you want to test, eg. form field where user has to enter the description for the yes
// previousFormField = pass the form field name just previous to the formFieldToCheck, eg. where user selected yes
const allowEmpty = (formData, currentFormField, formFieldToCheck, previousFormField) => {
  if (
    currentFormField === formFieldToCheck &&
    formData[previousFormField] === "yes" &&
    formData[currentFormField].length === 0
  ) {
    return {
      error: true,
      message:
        "Please enter your disability or select no in the previous question",
    }
  }
}


export const calculateTotalSum = (totalSelected) => {
  const amounts = Object.values(totalSelected).map(Number)
  const totalSum = amounts.reduce((sum, amount) => sum + amount, 0)
  return totalSum
}